import React from 'react'
import Header from 'components/features/Header'

import FeatureSet, {FeatureSetProps} from 'components/features/FeatureSet'
import SiteMetadata from 'components/SiteMetadata'

import image1 from 'images/features/blade1/hierarchy.svg'
import image2 from 'images/features/blade1/linking.svg'
import image3 from 'images/features/blade1/grouping.svg'
import image4 from 'images/features/blade2/checklists.svg'
import image5 from 'images/features/blade2/within a card.svg'
import image6 from 'images/features/blade2/copy paste.svg'
import image7 from 'images/features/blade3/progress indicators.svg'
import image8 from 'images/features/blade3/triggers.svg'
import image9 from 'images/features/blade3/checklists.svg'
import image10 from 'images/features/blade4/label.svg'
import image11 from 'images/features/blade4/insert_emoticon-24px.svg'
import image12 from 'images/features/blade4/drag and drop.svg'
import image13 from 'images/features/blade5/Story points.svg'
import image14 from 'images/features/blade5/Card numbers.svg'
import unicodeNonbreakingSpace from 'styles/constants/unicodeNonbreakingSpace'
import GDPR from 'components/features/GDPR'
import BottomCTA from 'components/BottomCTA'

const blade1: FeatureSetProps = {
  sectionTitle: 'Establish card relationships',
  features: [
    {
      title: 'Hierarchy',
      body: `Nest cards under the parent, or multiple parents, to create${unicodeNonbreakingSpace}relationships.`,
      image: image1,
    },
    {
      title: 'Linking',
      body: `Link cards across different boards and easily create${unicodeNonbreakingSpace}dependencies.`,
      image: image2,
    },
    {
      title: 'Groupings',
      body: `Group related child cards${unicodeNonbreakingSpace}together.`,
      image: image3,
    },
  ],
  footer: <GDPR />,
}

const blade2: FeatureSetProps = {
  sectionTitle: 'Easily create child cards',
  features: [
    {
      title: 'Checklists',
      body: `Convert checklist items into child${unicodeNonbreakingSpace}cards.`,
      image: image4,
    },
    {
      title: 'Within a card',
      body: `Attach existing cards as child cards or create new child cards directly on the${unicodeNonbreakingSpace}parent.`,
      image: image5,
    },
    {
      title: 'Copy and paste',
      body: `Copy and paste multiple tasks and automatically create new child cards for each${unicodeNonbreakingSpace}entry.`,
      image: image6,
    },
  ],
}

const blade3: FeatureSetProps = {
  sectionTitle: 'Track overall progress',
  features: [
    {
      title: 'Progress indicators',
      body: `Look at the front or card back of the parent card to quickly check how many tasks are${unicodeNonbreakingSpace}completed.`,
      image: image7,
    },
    {
      title: 'Triggers',
      body: `Choose how to mark a child card as complete. You can either mark it done via due date or by moving it to a designated “done”${unicodeNonbreakingSpace}column.`,
      image: image8,
    },
    {
      title: 'Checklists',
      body: `If you complete all items in a card’s checklist, the progress bar and checklist badge both turn${unicodeNonbreakingSpace}green.`,
      image: image9,
    },
  ],
}

const blade4: FeatureSetProps = {
  sectionTitle: 'Organize cards',
  features: [
    {
      title: 'Labels',
      body: `Choose from 11 different colored labels or enable color-blind friendly mode. You can even use a shortcut so child cards inherit labels from the parent${unicodeNonbreakingSpace}card.`,
      image: image10,
    },
    {
      title: 'Emojis',
      body: `Add emojis to visually represent your tasks, like 🐞 smashing bugs or 🏡${unicodeNonbreakingSpace}redesigning your${unicodeNonbreakingSpace}homepage.`,
      image: image11,
    },
    {
      title: 'Drag-and-drop',
      body: `Drag-and-drop child cards right on the parent to update priority or change their${unicodeNonbreakingSpace}status.`,
      image: image12,
    },
  ],
}

const blade5: FeatureSetProps = {
  sectionTitle: 'Connect with other Power-Ups',
  features: [
    {
      title: 'Agile Tools by Corello',
      body: `Add story points and WIP limits to your${unicodeNonbreakingSpace}cards.`,
      image: image13,
    },
    {
      title: 'Card Numbers by Reenhanced',
      body: `Assign a unique number to your cards for easier${unicodeNonbreakingSpace}reference.`,
      image: image14,
    },
  ],
}

const FeaturesPage = () => (
  <>
    <SiteMetadata
      title="Features"
      metaDescription="Hello Epics helps you establish card relationships, easily create child cards, track overall progress and more."
    />
    <Header />
    {[blade1, blade2, blade3, blade4, blade5].map((blade, index) => (
      <FeatureSet key={index} {...blade} />
    ))}
    <BottomCTA />
  </>
)

export default FeaturesPage
