import React from 'react'
import styled from '@emotion/styled'
import {typography, margin, breakpoint} from 'styles'

const Text = styled.p({
  ...margin.top(24),
  ...typography.caption,
  textAlign: 'center',
  fontStyle: 'italic',

  [breakpoint.mediumAndUp]: {
    ...typography.body,
  },
})

const GDPR: React.FunctionComponent = () => (
  <Text>
    Hello Epics is committed to managing your data securely and transparently.
    We do not store any of your content — in fact, we never even see it. We will
    never share your personal information, and we are GDPR compliant.
  </Text>
)

export default GDPR
