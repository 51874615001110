import React from 'react'
import styled from '@emotion/styled'
import {typography, margin, breakpoint, padding, colors} from 'styles'
import Wrapper from 'components/Wrapper'
import spacing from 'styles/constants/spacing'
import ThreeColumnGrid from 'components/ThreeColumnGrid'
import Card, {CardProps} from 'components/ThreeColumnGrid/Card'

export interface FeatureSetProps {
  sectionTitle: string
  features: CardProps[]
  footer?: JSX.Element
}

const Blade = styled.div({
  ...margin.bottom(64),
  borderRadius: spacing(24),
  backgroundColor: colors.primary[0],
  ...padding(24),
})

const Headline = styled.h3({
  ...padding.top(24),
  ...typography.headline3,
  textAlign: 'center',

  [breakpoint.mediumAndUp]: {
    ...typography.headline3,
  },
})

const FeatureSet: React.FunctionComponent<FeatureSetProps> = ({
  sectionTitle,
  features,
  footer,
}) => {
  return (
    <Wrapper>
      <Blade>
        <Headline>{sectionTitle}</Headline>
        <ThreeColumnGrid>
          {features.map((feature, index) => (
            <Card {...feature} key={index} />
          ))}
        </ThreeColumnGrid>
        {footer && footer}
      </Blade>
    </Wrapper>
  )
}

export default FeatureSet
