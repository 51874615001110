import React from 'react'
import Wrapper from 'components/Wrapper'
import styled from '@emotion/styled'
import {typography, margin} from 'styles'

const Container = styled.div({
  position: 'relative',
  ...margin.topOfPage(),
  ...margin.bottom(36),
})

const Heading = styled.h1({
  textAlign: 'center',
  ...typography.headline1,
})

const Header: React.FunctionComponent = () => {
  return (
    <Container>
      <Wrapper
        css={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <Heading>Features</Heading>
      </Wrapper>
    </Container>
  )
}

export default Header
